<template>
</template>
<script>

import "bootstrap/dist/css/bootstrap.min.css";
import axios from 'axios';
import xml2js from 'xml2js';
export default ({
  data(){
    return{
     file:'',
     xmlItems:[]
    }
  },
  created(){

    this.file=this.readXML('../../../static/arcanite_sitemap.xml')

  },
  methods:{

      readXML(filePath) {
          // 创建一个新的xhr对象
          let xhr = null;
          if (window.XMLHttpRequest) {
            xhr = new XMLHttpRequest()
          } else {// IE
            xhr = new ActiveXObject('Microsoft.XMLHTTP')
          }
          xhr.open('GET', filePath, false);
          xhr.overrideMimeType('text/html;charset=utf-8');
          xhr.send(null);
          let div = document.createElement("div");
          if(typeof xhr.responseText === "string")div.innerHTML = xhr.responseText.replace(/\s*/g,"");
        //   this.htmlDeal(div.childNodes[0].childNodes)
        },

    htmlDeal(data){
          data.forEach(item=>{
            // console.dir(item)
            if(item.nodeName === "LBS"){
              let list = [];
              item.childNodes.forEach(item1 =>{
                let obj = {};
                if(item1.nodeName === "LB"){
                  item1.childNodes.forEach(item2=>{
                    if(item2.nodeName === "LABEL"){
                      obj.label = item2.textContent;
                    }else if(item2.nodeName === "TEXT"){
                      obj.value = item2.textContent;
                    }
                  })
                }
                list.push(obj);
              });
              this.titleList = list;
            }
            else if(item.nodeName === "TABLE"){
              let list = [];
              item.childNodes.forEach(item1 =>{
                let obj = {};
                if(item1.nodeName === "THEAD"){
                  item1.childNodes.forEach(item2 =>{
                    let aaa = [];
                    if(item2.nodeName === "TR"){
                      item2.childNodes.forEach((item3,index3) =>{
                        let obj1 = {};
                        if(item3.nodeName === "TH"){
                          obj1.value = item3.textContent;
                        }
                        aaa.push(obj1)
                      });
                    }
                    obj.head = aaa;
                    list.push(obj);
                  })
                }else if(item1.nodeName === "TBODY"){
                  let bbb = [];
                  item1.childNodes.forEach(item2 =>{
                    if(item2.nodeName === "TR"){
                      let ccc = [];
                      item2.childNodes.forEach((item3,index3) =>{
                        let obj2 = {};
                        if(item3.nodeName === "TD"){
                          if(item3.childNodes && item3.childNodes.length > 1){
                            item3.childNodes.forEach(item4=>{
                              if(item4.nodeName === "TEXT")obj2.value = item4.textContent;
                              else if(item4.nodeName === "EDIT")obj2.edit = item4.textContent;
                            })
                          }else {
                            obj2.value = item3.textContent;
                          }
                        }
                        ccc.push(obj2)
                      });
                      bbb.push(ccc)
                    }
                  });
                  obj.body = bbb;
                  list.push(obj);
                  this.tableList = list;
                }
              })

            }

          })
        },
  },
  beforeDestroy() {
  }
})
</script>
<style lang="scss" scoped>
</style>
